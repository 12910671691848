import { computed, ref, watch } from 'vue'
import { Router } from 'vue-router'
import Swal from 'sweetalert2'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import { useStore } from '@web/store'
import { Schedule } from '@web/store/types/modules/schedule'
import WEEK_DAY_NAME from '@web/consts/WEEK_DAY_NAME'
import i18n from '@web/plugins/i18n'

type params = {
  router: Router;
}

type weekDayItem = {
  id: number;
  name: string;
  abbreviation: string;
  isSelected: boolean;
}

function useRemindTraining ({ router }: params) {
  const store = useStore()
  const isCreatePage = computed<boolean>(() => router.currentRoute.value.name === 'settingsReminderNew')
  const sendingData = ref(false)
  const changedData = ref(false)
  const isChangedData = computed(() => isCreatePage.value ? true : changedData.value)
  const remindTrainingTime = ref<string>('10:00:00')
  const schedule = computed<Schedule | undefined>(() => store.getters['schedule/getScheduleById'](router.currentRoute.value.params.id.toString()))

  const defaultWeekDayList: Dictionary<weekDayItem> = {}
  for (const id in WEEK_DAY_NAME) {
    defaultWeekDayList[id] = {
      id: Number(id),
      name: i18n.global.t(`week_day.${WEEK_DAY_NAME[id]}.long`),
      abbreviation: i18n.global.t(`week_day.${WEEK_DAY_NAME[id]}.short`),
      isSelected: Number(id) <= 5
    }
  }
  const weekDayList = ref<Dictionary<weekDayItem>>(defaultWeekDayList)
  const toggleWeekDayStatus = (id: number) => {
    weekDayList.value[id].isSelected = !weekDayList.value[id].isSelected
    changedData.value = true
  }

  const buttonClasses = computed(() => {
    return isCreatePage.value ? '-create' : '-update'
  })

  function checkNotificationsDisable (isDisabledNotifications: boolean, f?: () => void) {
    if (isDisabledNotifications) {
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('error.error'),
        text: i18n.global.t('enabledPushOrEmailNotifications'),
        showConfirmButton: true,
        confirmButtonText: i18n.global.t('enableNotifications'),
        showCancelButton: true,
        cancelButtonText: i18n.global.t('close')
      }).then(result => {
        if (result.value) {
          router.push({ name: 'settingsNotifications' })
        }
      })
    } else if (typeof f === 'function') {
      f()
    }
  }

  async function init () {
    await store.dispatch('schedule/fetchCached')

    if (schedule.value) {
      Object.values(weekDayList.value).forEach(day => {
        weekDayList.value[day.id].isSelected = false
      })
      schedule.value.repeatDays.forEach(dayNumber => {
        weekDayList.value[dayNumber].isSelected = true
      })
      remindTrainingTime.value = schedule.value.time
    }
  }

  function cancel () {
    router.push({
      name: 'settingsReminders'
    })
  }

  async function remove (id: string): Promise<void> {
    const result = await Swal.fire({
      title: i18n.global.t('areYouSureDeleteIt'),
      confirmButtonText: i18n.global.t('yes'),
      cancelButtonText: i18n.global.t('no'),
      showCancelButton: true
    })
    if (result.isConfirmed) {
      await store.commit('schedule/removeSchedule', id)
      await store.dispatch('schedule/update')
      router.push({
        name: 'settingsReminders'
      })
    }
  }

  async function saveChanges () {
    if (sendingData.value) {
      return
    }

    try {
      sendingData.value = true

      const repeatDays = Object.values(weekDayList.value)
        .filter(({ isSelected }) => isSelected)
        .map(({ id }) => id)
      store.commit('schedule/addSchedule', {
        id: remindTrainingTime.value,
        time: remindTrainingTime.value,
        repeatDays
      })

      if (router.currentRoute.value.params.id && remindTrainingTime.value !== router.currentRoute.value.params.id) {
        store.commit('schedule/removeSchedule', router.currentRoute.value.params.id?.toString())
      }

      if (isCreatePage.value || remindTrainingTime.value !== router.currentRoute.value.params.id) {
        router.replace({
          name: 'settingsReminderUpdate',
          params: {
            id: remindTrainingTime.value
          }
        })
      }

      await store.dispatch('schedule/update')

      await Swal.fire({
        icon: 'success',
        title: i18n.global.t('dataUpdated')
      })
    } catch (e) {
      sentry.captureException(e)
      Logger.error(e)
      Swal.fire({
        icon: 'error',
        title: i18n.global.t('Error'),
        text: i18n.global.t('errorTryLoadData')
      })
    } finally {
      sendingData.value = false
      router.push({
        name: 'settingsReminders'
      })
    }
  }

  watch(remindTrainingTime, (newTime) => {
    if (newTime !== schedule.value?.time) {
      changedData.value = true
    }
  })

  return {
    sendingData,
    isChangedData,
    remindTrainingTime,
    weekDayList,
    toggleWeekDayStatus,
    checkNotificationsDisable,
    init,
    saveChanges,
    buttonClasses,
    cancel,
    remove,
    isCreatePage
  }
}

export default useRemindTraining
