
import { defineComponent, onMounted } from 'vue'

import SettingsContent from '@web/components.v2/Settings/Content.vue'
import AcButton from '@ui-kit/components/AcButton.vue'
import AsPreloader from '@web/components/AsPreloader.vue'

import useRemindTrainingList from '@web/composition/useRemindTrainingList'
import Swal from 'sweetalert2'
import i18n from '@web/plugins/i18n'
import sentry from '@web/common/sentry'
import Logger from '@web/common/Logger'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Reminds',
  components: {
    AcButton,
    AsPreloader,
    SettingsContent
  },
  setup () {
    const {
      init,
      ...remindTrainingListData
    } = useRemindTrainingList({ router: useRouter() })

    onMounted(async () => {
      try {
        await init()
      } catch (e) {
        Swal.fire({
          title: i18n.global.t('error.error'),
          text: i18n.global.t('error.loadingData'),
          showConfirmButton: false,
          icon: 'error'
        })
        sentry.captureException(e)
        Logger.error(e)
      }
    })

    return {
      ...remindTrainingListData
    }
  }
})
