
import { defineComponent, computed } from 'vue'
import { MqResponsive } from 'vue3-mq'

import SettingsContent from '@web/components.v2/Settings/Content.vue'
import AcButton from '@ui-kit/components/AcButton.vue'
import WeekDays from '@web/components.v2/Settings/Reminds/WeekDays.vue'
import AsDateTimePicker from '@web/components/AsDateTimePicker.vue'

// import usePushSubscribe from '@web/composition/usePushSubscribe'
// import useEmailSubscribe from '@web/composition/useEmailSubscribe'
import useRemindTraining from '@web/composition/useRemindTraining'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'Remind',
  components: {
    SettingsContent,
    AcButton,
    MqResponsive,
    WeekDays,
    AsDateTimePicker
  },
  setup () {
    // const pushSubscribe = usePushSubscribe()
    // const emailSubscribe = useEmailSubscribe()
    // const disabledRemindTraining = computed(() => !pushSubscribe.subscribed.value && !emailSubscribe.subscribed.value)
    const {
      init,
      remindTrainingTime,
      isCreatePage,
      sendingData,
      isChangedData,
      ...remindTraining
    } = useRemindTraining({
      router: useRouter()
    })

    const isDisabledSaveBtn = computed(() => !isChangedData.value || sendingData.value)
    const isShowRemoveBtn = computed(() => !isCreatePage.value)

    const updateRemindTrainingTime = (time: string) => {
      remindTrainingTime.value = `${time}:00`
    }

    init()

    return {
      remindTrainingTime,
      ...remindTraining,
      isDisabledSaveBtn,
      isShowRemoveBtn,
      updateRemindTrainingTime
    }
  }
})
