
import { defineComponent, ref, computed } from 'vue'

import { useMq } from 'vue3-mq'

export default defineComponent({
  name: 'WeekDays',
  components: {},
  props: {
    days: {
      type: Object as () => Dictionary<{
        id: number;
        name: string;
        abbreviation: string;
        isSelected: boolean;
      }>,
      required: true
    }
  },
  emits: ['update:modelValue', 'input'],
  setup (props, ctx) {
    const mq = useMq()
    const showBlock = ref(false)

    const selectedItems = computed<string>(() => {
      return Object.values(props.days)
        .filter(day => day.isSelected)
        .map(day => day.abbreviation)
        .join(', ')
    })

    function onInput (e) {
      ctx.emit('input', e)
    }

    function toggleShowBlock () {
      showBlock.value = !showBlock.value
    }

    return {
      mq,
      showBlock,
      selectedItems,
      onInput,
      toggleShowBlock
    }
  }
})
