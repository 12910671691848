import { Router } from 'vue-router'
import { useStore } from '@web/store'
import { computed, ref } from 'vue'
import { Schedule } from '@web/store/types/modules/schedule'
import WEEK_DAY_NAME from '@web/consts/WEEK_DAY_NAME'
import isEqual from 'lodash.isequal'
import i18n from '@web/plugins/i18n'
import Swal from 'sweetalert2'

export default function useRemindTrainingList ({ router }: { router: Router }) {
  const store = useStore()

  function addNotification () {
    router.push({
      name: 'settingsReminderNew'
    })
  }

  function getDaysName (days: number[]): string {
    if (days.length === 7) {
      return i18n.global.t('Daily')
    }

    if (days.length === 5 && isEqual(days, [1, 2, 3, 4, 5])) {
      return i18n.global.t('onWeekdays')
    }

    if (days.length === 2 && isEqual(days, [6, 7])) {
      return i18n.global.t('onWeekend')
    }

    return days
      .map(day => i18n.global.t(`week_day.${WEEK_DAY_NAME[day]}.short`))
      .join(', ')
  }

  const schedules = computed<Dictionary<Schedule>>(() => store.getters['schedule/schedules'])
  const scheduleList = computed<{
    id: string;
    days: string;
    time: string;
  }[]>(() => {
    return Object.values(schedules.value).map(schedule => {
      let days = ''
      if (schedule.repeatDays) {
        days = getDaysName(schedule.repeatDays)
      }
      return {
        id: schedule.id,
        days,
        time: schedule.time.substring(0, 5)
      }
    })
  })

  const isLoading = ref<boolean>(false)
  async function init () {
    isLoading.value = true
    await store.dispatch('schedule/fetchCached')
    isLoading.value = false
  }

  const openSchedule = (id: string): void => {
    router.push({
      name: 'settingsReminderUpdate',
      params: {
        id
      }
    })
  }

  async function removeSchedule (id: string): Promise<void> {
    const result = await Swal.fire({
      title: i18n.global.t('areYouSureDeleteIt'),
      confirmButtonText: i18n.global.t('yes'),
      cancelButtonText: i18n.global.t('no'),
      showCancelButton: true
    })
    if (result.isConfirmed) {
      await store.commit('schedule/removeSchedule', id)
      await store.dispatch('schedule/update')
      router.push({
        name: 'settingsReminders'
      })
    }
  }

  return {
    scheduleList,
    addNotification,
    init,
    isLoading,
    openSchedule,
    removeSchedule
  }
}
